import React from 'react';
import Skills from './Skills';

export default function About() {
  return (
    <>
      <section id="about-me">
        <h2>About Me</h2>
        <p>
          Hello! My name is Daniel Ors, and I am a full-stack developer and
          senior software engineer with a background in enterprise and federal client
          application delivery, digital strategy consultancy, and technical
          marketing.
        </p>
        <br />

        <p>
          When I’m not designing or building my next great project, I’m usually
          making progress on my bucket list goal of visiting every National Park
          in the United States, taking my dog out for a walk, or cooking a new recipe from my family’s secret Cuban recipe box!
          Want a bite of puerco asado? Give me an hour, and you’ll be devouring
          the greatest pork you’ve ever had.
        </p>
        <br />

        <p>
          I was born with profound hearing loss and wear a cochlear implant on
          my left ear. As such, one of my lifetime passions has been advocating
          for accessibility in modern standards of technology, from proper
          captioning of video in all platforms to screen-reader friendly
          formatting of web services. If you’re looking for innovative digital
          strategy, accessible-first web applications, a dedicated
          collaboration-oriented developer for your team, or creative digital
          advertising approaches, look no further!
          <br />
          <span id="email-me-link">
            <a href="mailto:hiredanielors@gmail.com">Hit me up!</a>
          </span>
        </p>
      </section>
      <hr />
      <Skills />
    </>
  );
}
