import React, { Component } from 'react';
import Header from '../Header';
import About from '../About';

export default class HomeRoute extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render() {
    document.title = 'Daniel Ors - Full Stack Developer';
    return (
      <>
        <Header />
        <hr />
        <About />
      </>
    )
  }
}