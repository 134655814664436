import React, { Component } from 'react';
import Resume from '../Resume';
import Footer from '../Footer';

export default class ResumeRoute extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render() {
    document.title = 'Daniel Ors - Resume';
    return (
        <>
          <Resume />
          <Footer />
        </>
    )
  }
}