import React from "react";
import "./Projects.css";

export default function Projects() {
  return (
    <section id="projects">
      <h1>Projects</h1>
      <section id="mycongress">
        <a
          href="https://mycongress.now.sh/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://raw.githubusercontent.com/thinkful-ei-armadillo/congress-app-client/master/src/images/dashboard.png"
            alt="screenshot of the homepage of MyCongress"
            id="mycongress-screencap"
          />
          <img
            src="https://raw.githubusercontent.com/danielors/portfolio-react/master/src/images/mcongress-mobile.png"
            alt="screenshot of the mobile homepage of MyCongress"
            id="mycongress-mobile-screencap"
          />
        </a>
        <h3 id="mycongress-title">MyCongress</h3>
        <br />
        <p>
          An app that brings Congressional data to your fingertips. Built in an
          Agile/Scrum development process with ReactJS, Node, Express,
          PostgreSQL, D3.js, Cypress, Mocha, Chai, and secured with bcrypt/JWT. Deployed with Heroku and Zeit Now. 
          Code tracking managed with Git and code reviews handled with GitHub PRs.
        </p><br />
        <p>
          I served as Project Manager and Scrum Master in collaboration with{" "}
          <a
            href="https://github.com/owencyr"
            target="_blank"
            rel="noopener noreferrer"
            className="collab-name"
          >
            Owen Cyr
          </a>{" "}
          (Testing Lead),{" "}
          <a
            href="https://github.com/secilreel"
            target="_blank"
            rel="noopener noreferrer"
            className="collab-name"
          >
            Irem Seçil Reel Sen
          </a>{" "}
          (Design/UX Lead),{" "}
          <a
            href="https://github.com/lahrime"
            target="_blank"
            rel="noopener noreferrer"
            className="collab-name"
          >
            Ali Lahrime
          </a>{" "}
          (Product Manager), and{" "}
          <a
            href="https://github.com/paypay43"
            target="_blank"
            rel="noopener noreferrer"
            className="collab-name"
          >
            Payman Kossari
          </a>{" "}
          (Marketing Lead).
        </p>
        <br />
        <p>
          <b>Built With:</b>
        </p>
        <ul className="built-with">
          <li>
            <i className="fab fa-html5 fa-lg" id="html5-icon" alt="HTML5" />
          </li>
          <li>
            <i className="fab fa-css3-alt fa-lg" id="css3-icon" alt="CSS3" />
          </li>
          <li>
            <i className="fab fa-js fa-lg" id="js-icon" alt="JavaScript" />
          </li>
          <li>
            <i className="fab fa-react fa-lg" id="react-icon" alt="ReactJS" />
          </li>
          <li>
            <i className="fab fa-node-js fa-lg" id="node-icon" alt="NodeJS" />
          </li>
          <li>
            <i
              className="fas fa-database fa-lg"
              id="postgresql-icon"
              alt="PostgreSQL"
            />
          </li>
        </ul>
        <br />
        <a
          href="https://mycongress.now.sh/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="demo-button">Demo</button>
        </a>{" "}
        <a
          href="https://github.com/danielors/congress-app-client"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="code-button">View the Code</button>
        </a>
      </section>
      <section id="refreshd">
        <a
          href="https://refreshd.now.sh/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://raw.githubusercontent.com/danielors/portfolio/master/images/cap2.png"
            alt="screenshot of the homepage of Refreshd, showing its dashboard feature"
            id="refreshed-screencap"
          />
          <img
            src="https://raw.githubusercontent.com/danielors/portfolio-react/master/src/images/refreshd-mobile.png"
            alt="screenshot of the mobile homepage of Refreshd, showing its dashboard feature"
            id="refreshed-mobile-screencap"
          />
        </a>
        <h3 id="refreshd-title">Refreshd</h3>
        <br />
        <p>
          With Refreshd, I sought to solve the ever-growing problem of food
          waste by building a full-stack food sharing platform, providing a
          forum for you to share your extra food with those in need. Built with React, Node, Express, PostgreSQL, and user
          accounts secured with bcrypt and JWT. Deployed with Heroku and Zeit Now.
        </p>
        <br />
        <p>
          <b>Built With:</b>
        </p>
        <ul className="built-with">
          <li>
            <i className="fab fa-html5 fa-lg" id="html5-icon" alt="HTML5" />
          </li>
          <li>
            <i className="fab fa-css3-alt fa-lg" id="css3-icon" alt="CSS3" />
          </li>
          <li>
            <i className="fab fa-js fa-lg" id="js-icon" alt="JavaScript" />
          </li>
          <li>
            <i className="fab fa-react fa-lg" id="react-icon" alt="ReactJS" />
          </li>
          <li>
            <i className="fab fa-node-js fa-lg" id="node-icon" alt="NodeJS" />
          </li>
          <li>
            <i
              className="fas fa-database fa-lg"
              id="postgresql-icon"
              alt="PostgreSQL"
            />
          </li>
        </ul>
        <br />
        <a
          href="https://refreshd.now.sh/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="demo-button">Demo</button>
        </a>{" "}
        <a
          href="https://github.com/danielors/refreshd-client"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="code-button">View the Code</button>
        </a>
      </section>
      <section id="refreshd">
        <a
          href="https://andrew-daniel-spaced-repetition.now.sh/login"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://raw.githubusercontent.com/danielors/portfolio-react/master/src/images/learnjs.png"
            alt="screenshot of the dashboard page of learn.js"
            id="learnjs-screencap"
          />
          <img
            src="https://raw.githubusercontent.com/danielors/portfolio-react/master/src/images/learnjs-mobile-1.png"
            alt="screenshot of the mobile version of the dashboard page of learn.js"
            id="learnjs-mobile-screencap"
          />
        </a>
        <h3 id="learnjs-title">learn.js</h3>
        <br />
        <p>
          Learn.js is an application that uses our own version of a spaced
          repetition algorithm to help users learn JavaScript. Built with React,
          Node, Express, PostgreSQL, a queue-based spaced repetition algorithm,
          and secured with bcrypt and JWT. Deployed with Heroku and Zeit Now. Collaborative project with{" "}
          <a href="https://github.com/andrewbituin" className="collab-name">
            Andrew Bituin
          </a>
          .
        </p>
        <br />
        <p>
          To sign in and demo the app's features, you can sign in with:
          <br />
          <i>u: demouser</i>
          <br />
          <i>pw: Demouser123!</i>
        </p>
        <br />
        <p>
          <b>Built With:</b>
        </p>
        <ul className="built-with">
          <li>
            <i className="fab fa-html5 fa-lg" id="html5-icon" alt="HTML5" />
          </li>
          <li>
            <i className="fab fa-css3-alt fa-lg" id="css3-icon" alt="CSS3" />
          </li>
          <li>
            <i className="fab fa-js fa-lg" id="js-icon" alt="JavaScript" />
          </li>
          <li>
            <i className="fab fa-react fa-lg" id="react-icon" alt="ReactJS" />
          </li>
          <li>
            <i className="fab fa-node-js fa-lg" id="node-icon" alt="NodeJS" />
          </li>
          <li>
            <i
              className="fas fa-database fa-lg"
              id="postgresql-icon"
              alt="PostgreSQL"
            />
          </li>
        </ul>
        <br />
        <a
          href="https://andrew-daniel-spaced-repetition.now.sh/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="demo-button">Demo</button>
        </a>{" "}
        <a
          href="https://github.com/danielors/learnjs-client"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="code-button">View the Code</button>
        </a>
      </section>
      <section id="disabilities.io">
        <a
          href="http://disabilities.io/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://raw.githubusercontent.com/danielors/portfolio-react/master/src/images/disabilities-io-new.png"
            alt="screenshot of the homepage of Disabilities.io"
            id="dis-screencap"
          />
          <img
            src="https://raw.githubusercontent.com/danielors/portfolio-react/master/src/images/dis-mobile.png"
            alt="screenshot of the mobile homepage of Disabilities.io"
            id="dis-mobile-screencap"
          />
        </a>
        <h3 id="dis-title">disabilities.io</h3>
        <br />
        <p>
          An informational project to make the Americans with Disabilities Act
          more accessible. Currently planning out a roadmap to convert to React
          with an Express/PSQL backend for commenting and user accounts.
        </p>
        <br />
        <p>
          <b>Built With:</b>
        </p>
        <ul className="built-with">
          <li>
            <i className="fab fa-html5 fa-lg" id="html5-icon" alt="HTML5" />
          </li>
          <li>
            <i className="fab fa-css3-alt fa-lg" id="css3-icon" alt="CSS3" />
          </li>
        </ul>
        <br />
        <a
          href="http://disabilities.io/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="demo-button">Demo</button>
        </a>
      </section>
    </section>
  );
}
