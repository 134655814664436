import React from 'react';
import { Link } from 'react-router-dom';

export default function Nav() {
  return (
    <div className="nav">
      <nav role="navigation">
        <ul id="nav-list">
          <li id="home-nav">
            <Link to="/">Home</Link>
          </li>
          {/* <li id='projects-nav'><Link to="/projects">Projects</Link></li> */}
          <li id="resume-nav">
            <Link to="/resume">Resume</Link>
          </li>
        </ul>
      </nav>
    </div>
  );
}
