import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import Nav from './Nav';
import HomeRoute from './Routes/HomeRoute';
import ProjectsRoute from './Routes/ProjectsRoute';
import ResumeRoute from './Routes/ResumeRoute';
import './App.css';


export default class App extends Component {

  render() {
    return (
      <div className='App' id='app'>
        <Nav/>
        <main role='main'>
          <Switch>
            <Route 
              exact path={'/'}
              component={HomeRoute} />
            <Route 
              exact path={'/projects'}
              component={ProjectsRoute} />
            <Route 
              exact path={'/resume'}
              component={ResumeRoute} />
          </Switch>
        </main>
      </div>
    );
  }
}
