import React from 'react';

export default function Skills() {
  return (
      <section id='skills'>
        <h2>Skills</h2>
        <ul id='skills-list'>
          <li><i className="fab fa-html5 fa-2x" id='html5-icon' alt='HTML5'/></li>
          <li><i className="fab fa-css3-alt fa-2x" id='css3-icon' alt='CSS3'/></li>
          <li><i className="fab fa-js fa-2x" id='js-icon' alt='JavaScript'/></li>
          <li><i className="fab fa-react fa-2x" id='react-icon' alt='ReactJS'/></li>
          <li><i className="fab fa-node-js fa-2x" id='node-icon' alt='NodeJS'/></li>
          <li><i className="fas fa-database fa-2x" id='postgresql-icon' alt='PostgreSQL'></i></li>
          <li><i className="fas fa-universal-access fa-2x" id='accessibility-icon' alt='accessibility and a11y compliance'></i></li>
        </ul>
      </section>
  );
}