import React from 'react';
import { Link } from 'react-router-dom';
import resume from './images/OrsResume.pdf';

export default function Resume() {
  return (
    <div className="resume-page">
      <section id="resume-header">
        <img
          src="https://avatars3.githubusercontent.com/u/11906502?s=460&v=4"
          id="resume-profile-image"
          alt="Daniel Ors smiling for the camera!"
        />
        <h1>Daniel Ors</h1>
        <h2>Senior Software Engineer & Full Stack Developer</h2>
        <h3 id="clearance">United States Citizen - Secret Clearance (Interim TS)</h3>
        <ul id="contact-list">
          <li>
            <a href="mailto:hiredanielors@gmail.com" className="collab-name">
              hiredanielors@gmail.com
            </a>
          </li>
          <li>Seattle, WA</li>
          {/* <li>
            <Link
              to={resume}
              target="_blank"
              rel="noopener noreferrer"
              className="collab-name"
            >
              PDF version
            </Link>
          </li> */}
        </ul>
      </section>
      <hr />
      <section>
        <h2>Summary</h2>
        <p>
        Software Engineering Leader with over 11 years of experience across management consulting, B2B startups, and nonprofit sectors. Proven ability to lead cross-functional teams, manage full SDLC, and deliver innovative software solutions that drive business growth and client success. Adept at aligning technical strategy with business objectives, optimizing processes, and mentoring technical teams in fast-paced environments.
        </p>
      </section>
      <hr />
      <section>
        <h2>Skills</h2>
        <p>
          <b>Advanced:​</b> Python, Linux Shell Scripting, JavaScript Testing Frameworks, Express.js
        </p>
        <p>
          <b>Proficient:​</b> Oracle PL/SQL, PostgreSQL, REST APIs, Agile Scrum Methodology, Git, AWS Cloud
        </p>
        <p>
          <b>Expert:​</b> Team Leadership, Project Management, JavaScript,  React, Node.js, SDLC management
        </p>
      </section>
      <hr />
      <section>
        <h2>Experience</h2>
        <br />
        <h3>Elevate Government Solutions</h3>
        <p>
          <i>Senior Software Engineer</i>
        </p>
        <p>Washington, D.C. / Remote</p>
        <p>August 2019 – Present</p>
        <br />
        <p>
          Led agile team of 6 developers in iterative implementation of customized software for a federal client.
        </p>
        <p>
          Supported client procurement efforts via proposal writing and advising C-suite strategy.
        </p>
        <p>
          Consulted on key firm initiatives in direct coordination with senior leadership, building spec tools and technical prototypes for prospective client demos, including new internal generative AI tooling.
        </p>
        <p>
          Lead release engineer for 4 annual software upgrade lifecycles, including 2 COTS vendor upgrades and project-wide go-live windows.
        </p>
        <p>
         Built customized release automations and optimizations in AWS EC2 enclaves, contributing to 25% reduction in release delivery time and elimination of manual tasks introducing significant quality risk.
        </p>
        <p>
          Analyzed, developed, and customized software based on client requirements as PoC for key components.
        </p>
        <p>
          Coordinated with the Project Manager, Business Analysts, and cross-functional team leads to ensure implementations are completed on time and within
          budget.
        </p>
        <br />
        <br />
        <h3>Dewey Square Group</h3>
        <p>
          <i>Consultant - Web Development and Digital Strategy</i>
        </p>
        <p>Washington, D.C. / Remote</p>
        <p>December 2017 – December 2019</p>
        <br />
        <p>
        Consulted on website and application development as well as digital strategy needs for political advocacy and fundraising.
        </p>
        <p>
          Developed and maintained websites for clients with over 400,000
          monthly users.
        </p>
        <p>Serviced clients in non-profit, public, and private sectors. </p>
        <p>Counseled client leadership on digital strategy and implementations for short and long-term initiatives.</p>
        <p>
          Supported RFI and RFP response efforts, contributing to major client
          wins.
        </p>
        <br />
        <br />
        <h3>Alexander Graham Bell Association</h3>
        <p>
          <i>Digital Marketing Manager</i>
        </p>
        <p>Washington, D.C.</p>
        <p>April 2015 – November 2017</p>
        <br />
        <p>Managed digital marketing team of 4 and all campaigns including paid search & social campaigns - 410% increase in traffic.</p>
        <p>
          Web development lead - charged with management of 6 website
          properties.
        </p>
        <p>Advised leadership in organizational digital strategy decision making, from daily PR messaging to multi-year initiatives.
        </p>
        <p>
          Oversight of organizational rebranding and website redesign for 2017
          launch.
        </p>
        <p>
          Managed paid search & paid social campaigns - 410% increase in
          traffic.
        </p>
        <p>
          Produced scheduled communications for an audience of over 40,000
          readers.
        </p>
        <br />
        <br />
        <h3>Fueled</h3>
        <p>
          <i>Marketer</i>
        </p>
        <p>New York, New York</p>
        <p>August 2014 – March 2015</p>
        <br />
        <p>Managed team of writers, lead editor of content.</p>
        <p>Produced scheduled communications for audience of 100,000+.</p>
        <p>Achieved 16% increase in monthly web traffic during tenure.</p>
      </section>
      <hr />
      <section>
        <h2>Recent Achievements</h2>
        <br />
        <p>
          Designed and implemented internal generative AI tooling components to measure developer productivity. This helped the firm identify areas of improvement to streamline engineer growth capabilities and thus strengthen internal and externally embedded teams. Coordinated with senior leadership and cross-functional leads on key tool features and componentization for potential external B2B implementation. <b>[ELEVATE]</b>
        </p>
        <br />
        <p>
          Managed packaging and deployment of software 3.0 version to a closed ecosystem of tens of thousands of new users for federal client, including supervision of a 72 hour go-live window. Included broad cross-functional consensus building with technical managers and project leads as well as a focus on high-quality code practices. <b>[ELEVATE]</b>
          </p>
          <br />
        <p>
          Key contractor in retaining a multi-year contract to service a prominent non-profit voice in their technical needs across digital realms, from implementing new website application solutions to a more analytical approach to social media content and follower management to de-amplify and report on bot activity on major social platform. <b>[DEWEY]</b>
        </p>
      </section>
      <hr />
      <section>
        <h2>Recent Projects</h2>
        <p>
          <b>
            <a
              href="https://mycongress.now.sh"
              target="_blank"
              rel="noopener noreferrer"
              className="collab-name"
            >
              MyCongress
            </a>
            :
          </b>{' '}
          An app that brings Congressional data to your fingertips. Built in an
          Agile/Scrum development process with ReactJS, Node, Express,
          PostgreSQL, D3.js, Cypress, Mocha, Chai, and secured with bcrypt/JWT.
          Collaborative project with{' '}
          <a
            href="https://github.com/owencyr"
            target="_blank"
            rel="noopener noreferrer"
            className="collab-name"
          >
            Owen Cyr
          </a>
          ,{' '}
          <a
            href="https://github.com/secilreel"
            target="_blank"
            rel="noopener noreferrer"
            className="collab-name"
          >
            Irem Seçil Reel Sen
          </a>
          ,{' '}
          <a
            href="https://github.com/lahrime"
            target="_blank"
            rel="noopener noreferrer"
            className="collab-name"
          >
            Ali Lahrime
          </a>
          , and{' '}
          <a
            href="https://github.com/paypay43"
            target="_blank"
            rel="noopener noreferrer"
            className="collab-name"
          >
            Payman Kossari
          </a>
          .
        </p>
        <br />
        <p>
          <b>
            <a
              href="https://refreshd.now.sh"
              target="_blank"
              rel="noopener noreferrer"
              className="collab-name"
            >
              Refreshd​
            </a>
            :
          </b>{' '}
          A full stack food sharing platform. Built with React, Node, Express,
          PostgreSQL, and secured with bcrypt and JWT.
        </p>
        <br />
        <p>
          <b>
            <a
              href="https://andrew-daniel-spaced-repetition.now.sh"
              target="_blank"
              rel="noopener noreferrer"
              className="collab-name"
            >
              Learn.js
            </a>
          </b>
          : An application that utilizes spaced repetition to help users learn
          JavaScript! Built with React, Node, Express, PostgreSQL, a queue-based
          spaced repetition algorithm, and secured with bcrypt and JWT.
          Collaborative project with{' '}
          <a href="https://github.com/andrewbituin" className="collab-name">
            Andrew Bituin
          </a>
          .
        </p>
        <br />
        <p>
          <b>
            <a
              href="http://disabilities.io"
              target="_blank"
              rel="noopener noreferrer"
              className="collab-name"
            >
              Disabilities.io​
            </a>
            :
          </b>{' '}
          An informational project to make the Americans with Disabilities Act
          more accessible. Strictly HTML5/CSS3 for the moment, but planning out
          a roadmap to convert to React with an Express/PSQL backend for
          commenting and user accounts.
        </p>
      </section>
      <hr />
      <section>
        <h2>Education</h2>
        <br />
        <h3>Dartmouth College</h3>
        <p>
          <i>Bachelor of Arts: Government, Political Theory</i>
        </p>
        <p>Graduation: June 2014</p>
      </section>
    </div>
  );
}
