import React, { Component } from 'react';
import Projects from '../Projects';
import Footer from '../Footer';

export default class ProjectsRoute extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render() {
    document.title = 'Daniel Ors - Projects';
    return (
      <>
        <Projects />
        <hr />
        <Footer />
      </>
    )
  }
}