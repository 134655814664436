import React, { Component } from 'react';

export default class Header extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <>
        <header role="banner" id="home">
          <img
            src="https://avatars3.githubusercontent.com/u/11906502?s=460&v=4"
            alt="Daniel Ors smiling for the camera"
          />
          <h1>Daniel Ors</h1>
          <p>
            Software Engineering Leader focused on delivering
            <br />
            feature-complete applications for clients on time and under budget.
          </p>
          <div id="header-links">
            <a
              href="https://github.com/danielors"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i
                className="fab fa-github fa-lg"
                id="github-icon"
                alt="github"
              />
            </a>
            <a
              href="https://www.linkedin.com/in/daniel-ors/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i
                className="fab fa-linkedin-in fa-lg"
                id="linkedin-icon"
                alt="linkedin link"
              />
            </a>
            <a href="mailto:hiredanielors@gmail.com">
              <i
                className="fas fa-paper-plane fa-lg"
                id="email-icon"
                alt="email me link"
              />
            </a>
          </div>
        </header>
      </>
    );
  }
}
