import React from 'react';

export default function Footer() {
  return (
    <footer>
      <div id='footer-links'>
          <a href='https://github.com/danielors' target='_blank' rel='noopener noreferrer'>
            <i className="fab fa-github fa-lg" id='footer-github-icon' alt='github'/>
          </a>
          <a href='https://www.linkedin.com/in/daniel-ors/' target='_blank' rel='noopener noreferrer'>
            <i className="fab fa-linkedin-in fa-lg" id='footer-linkedin-icon' alt='linkedin link'/>
          </a>
          <a href='mailto:hiredanielors@gmail.com'>
            <i className="fas fa-paper-plane fa-lg" id='footer-email-icon' alt='email me link'/>
          </a>
      </div>
    </footer>
  );
};